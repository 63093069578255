import React, { useEffect, useState } from "react";
import {
  UNASSIGNEDTRIPS,
  UPDATE_STATUS,
  GET_PENDING_TRIPS,
} from "../Const/ApiConst";
import { instance } from "../Const/ApiHeader";
import { Form, Table } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import "./LoaderAnn.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Box, Breadcrumbs, Tab, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { toastMessage } from "../store/toast";

function FilteredTableComponent(params) {
  const columns = [
    {
      name: "Trip #",
      selector: (row) => row.tripNo,
      sortable: true,
    },
    {
      name: "Full Name",
      selector: (row) => row.customerName,
      sortable: true,
    },
    {
      name: "Pickup Date & Time",
      selector: (row) => (
        <div>
          <div>{row.scheduledTime}</div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Pickup Location",
      selector: (row) => (
        <Tooltip title={row.source}>
          <span className="d-flex flex-column">
            <span style={{ cursor: "alias", fontWeight: "bold" }}>
              {row.source}
            </span>
            <span>
              {row.stops.length !== 0 ? row.stops.length + " Stops" : ""}
            </span>
          </span>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: "Drop off Location",
      selector: (row) => (
        <Tooltip title={row.destination}>
          <span style={{ cursor: "alias" }}>{row.destination}</span>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => (
        <div className="text-end">{"$ " + row.totalAmount}</div>
      ),
      sortable: true,
      style: { display: "flex", justifyContent: "end" },
    },
    {
      name: "Payment Status",
      selector: (row) =>
        row.paymentReference === "" ||
        row?.paymentReference === undefined ||
        row?.paymentReference === null
          ? "Payment Pending"
          : "Payment Completed",
      sortable: true,
    },
    {
      name: "Document Status",
      selector: (row) => (
        <Tooltip title={row.documentStatus}>
          <span
            style={{
              cursor: "alias",
              fontWeight: "bold",
              color:
                row?.customerdata[0]?.documentStatus === "Verified"
                  ? "green"
                  : row?.customerdata[0]?.documentStatus === "Rejected"
                  ? "red"
                  : "blue",
            }}
          >
            {row?.customerdata[0]?.documentStatus}
          </span>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: "View",
      selector: (row) => (
        <>
          {row.tripStatus == "Booked" ? (
            <Link to={"/Trip_Action/" + row._id}>
              <Button
                size="small"
                variant="contained"
                // onClick={() => updateStatus(row._id)}
              >
                {buttonTitle}
              </Button>
            </Link>
          ) : (
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                updateStatus(row._id);
                dispatch(
                  toastMessage({
                    message: "Status updated successfully!",
                    toastStatus: true,
                    type: "success",
                  })
                );
              }}
            >
              {buttonTitle}
            </Button>
          )}
        </>
      ),
      sortable: true,
    },
  ];
  const dispatch = useDispatch();

  const [filteredTripData, setFilteredTripData] = useState([]);

  const [tripDataArray, setTripDataArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [buttonTitle, setButtonTitle] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [value, setValue] = useState("1");

  const loadData = async (page) => {
    setLoading(true);
    const filter = {
      status: params.data,
      page: page,
      per_page: perPage,
      searchKey: searchKey,
    };
    instance
      .post(UNASSIGNEDTRIPS, filter)
      .then((response) => {
        setTripDataArray(response.data.data);
        // console.log("data:", response.data.data);
        setTotalRows(response.data.total);
        // console.log("total", response.data.total);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handlePageChange = (page) => {
    loadData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const data = {
      page: page,
      per_page: newPerPage,
      searchKey: searchKey,
      status: params.data,
    };
    const response = await instance.post(UNASSIGNEDTRIPS, data);
    setTripDataArray(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  // useEffect(() => {
  //   loadData("Booked", 2);
  //   // console.log("first", page);
  //   // fetch page 1 of users
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    loadData(1, params.data);
    if (params.data === "Booked") {
      setButtonTitle("Trip Confirmed");
    } else if (params.data === "Trip Confirmed") {
      setButtonTitle("Enroute");
    } else if (params.data === "Enroute") {
      setButtonTitle("Arrived");
    } else if (params.data === "Arrived") {
      setButtonTitle("Onboard");
    } else if (params.data === "Onboard") {
      setButtonTitle("Completed");
    } else {
    }
  }, []);

  const updateStatus = async (id) => {
    const data = {
      tripId: id,
      status: buttonTitle,
    };
    await instance.post(UPDATE_STATUS, data);
    loadData(1);
  };

  const liveSearchAction = async (e) => {
    setSearchKey(e.target.value);
    setLoading(true);
    const filter = {
      page: 1,
      status: params.data,
      per_page: 10,
      searchKey: e.target.value,
      status: params.data,
    };
    instance
      .post(UNASSIGNEDTRIPS, filter)
      .then((response) => {
        setTripDataArray(response.data.data);
        // console.log("data:", response.data.data);
        setTotalRows(response.data.total);
        // console.log("total", response.data.total);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return loading ? (
    <div className="d-flex justify-content-center w-100 p-5">
      <div class="loader"></div>
    </div>
  ) : (
    <>
      <div className="col-4">
        {" "}
        <Form.Control
          type="search"
          placeholder="Search..."
          onBlur={(e) => liveSearchAction(e)}
        />
      </div>
      <DataTable
        responsive={true}
        columns={columns}
        data={tripDataArray}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </>

    // <Table>
    //   <thead>
    //     <tr>
    //       <th>Trip #</th>
    //       <th>Full Name</th>
    //       <th>Pickup Date & Time</th>
    //       <th>Pickup Location</th>
    //       <th>Dropoff Location</th>
    //       <th>Total Amount</th>
    //       <th>Payment Status</th>
    //       <th></th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {filteredTripData?.length === 0 ? (
    //       <tr>
    //         <td colSpan={7} className="text-center">
    //           No Data
    //         </td>
    //       </tr>
    //     ) : (
    //       filteredTripData?.map((res, i) => (
    //         <tr key={i} style={{ fontSize: "12px" }}>
    //           <td>{res?.tripNo}</td>
    //           <td>{res?.customerName}</td>
    //           <td>
    //             <div>{res?.scheduledDate}</div>
    //             <div>{res?.scheduledTime}</div>
    //           </td>
    //           <td>{res?.source}</td>
    //           <td>{res?.destination}</td>
    //           <td>$ {res?.totalAmount}</td>
    //           <td>
    //             {res?.paymentReference === "" ||
    //             res?.paymentReference === undefined ||
    //             res?.paymentReference === null
    //               ? "Payment Pending"
    //               : "Payment Completed"}
    //           </td>
    //           <td>
    //             {params.data === "Booked" ? (
    //               <Link to={`/Trip_Action/${res._id}`}>
    //                 <Button size="small" variant="contained">
    //                   View
    //                 </Button>
    //               </Link>
    //             ) : (
    //               <Button
    //                 size="small"
    //                 variant="contained"
    //                 onClick={() => updateStatus(res._id)}
    //               >
    //                 {buttonTitle}
    //               </Button>
    //             )}
    //           </td>
    //         </tr>
    //       ))
    //     )}
    //   </tbody>
    // </Table>
  );
}

export default FilteredTableComponent;
